<template>
	<div class="c-block-list-visual-shortcuts-custom">
		<h2
			:class="[
				'text-label-spotbox font-darker-grotesque font-bold uppercase',
				'mb-lg',
			]"
			v-text="title"
		></h2>

		<!-- Desktop -->
		<div
			:class="[
				'c-block-list-visual-shortcuts-custom__desktop',
				'relative hidden >=1024:flex items-stretch',
			]"
		>
			<!-- Desktop Lens Flare -->
			<LensFlare
				id="c-block-list-visual-shortcuts-custom__flare--desktop"
				:class="[
					'absolute top-1/2 -left-6xl',
					'transform -translate-y-1/2',
					'w-1/2',
				]"
				:angle="-60"
				:min-blur-amount="35"
				:max-blur-amount="35"
				:flares="[
					{
						size: 700,
						offset: -75,
						opacity: 1,
						theme: 'primary',
					},
					{
						size: 700,
						offset: 200,
						opacity: 0.8,
						theme: 'secondary',
					},
				]"
			/>

			<!-- Desktop Content -->
			<div
				role="tablist"
				:class="[
					'c-block-list-visual-shortcuts-custom__buttons',
					'flex-1 flex flex-col',
				]"
			>
				<button
					v-for="(item, index) in linkItems"
					:id="`visual-list-tab-${_uid}-${index}`"
					:key="`button-${index}`"
					:aria-selected="activeIndex === index ? 'true' : 'false'"
					:aria-controls="`visual-list-panel-${_uid}-${index}`"
					:tabindex="activeIndex === index ? null : '-1'"
					role="tab"
					:class="[
						'c-block-list-visual-shortcuts-custom__tab',
						'relative flex-1 flex justify-between items-center',
						'px-2xl py-xl text-left group',
						'font-semibold font-darker-grotesque text-h4',
						'duration-300 ease-smooth-out',

						{
							'text-white bg-text': activeIndex === index,
							'text-text bg-background': activeIndex !== index,
						},
					]"
					@click="() => setActiveItem(item)"
					@keydown.up.prevent
					@keydown.down.prevent
					@keyup.up="() => tabsNavigateBy(-1)"
					@keyup.down="() => tabsNavigateBy(1)"
				>
					<span
						:class="[
							'mb-4 transform',
							'transition-transform duration-300 ease-smooth-out',

							{
								'group-hover:translate-x-12':
									activeIndex !== index,
							},
						]"
						v-text="item.content.title"
					></span>

					<SvgIconCaret
						:class="[
							'c-block-list-visual-shortcuts-custom__caret',
							'h-24 text-white transform -rotate-90',
							'duration-300 ease-smooth-out',

							{
								'opacity-0': activeIndex !== index,
							},
						]"
					/>

					<span
						v-if="
							index !== activeIndex &&
							index !== linkItems.length - 1
						"
						:class="[
							'absolute left-0 -bottom-1 z-10',
							'flex w-full px-2xl',
						]"
					>
						<span
							class="flex-1 border-b border-text opacity-20"
						></span>
					</span>
				</button>
			</div>

			<div
				:class="[
					'relative group',
					'w-7/12col flex-shrink-0 overflow-hidden',
					'bg-secondary u-color-theme-transition',
				]"
			>
				<div
					v-for="(item, index) in linkItems"
					v-show="index === activeIndex"
					:id="`visual-list-panel-${_uid}-${index}`"
					:key="`panel-${index}`"
					role="tabpanel"
					class="absolute left-0 top-0 w-full h-full transform"
					:aria-expanded="index === activeIndex ? 'true' : 'false'"
					:aria-hidden="index === activeIndex ? 'false' : 'true'"
				>
					<NuxtLinkExt
						:to="item.content.link[0]?.url || '#'"
						:target="item.content.link[0]?.target"
						class="absolute left-0 top-0 w-full h-full transform"
					>
						<UmbracoImageExt
							v-if="
								linkItems[index] &&
								linkItems[index].content.image
							"
							:widths="[345, 459, 752, 459 * 2, 752 * 2]"
							:alt="linkItems[index].content.image.altText"
							:source-url="linkItems[index].content.image.cropUrl"
							:source-width="linkItems[index].content.image.width"
							:source-height="
								linkItems[index].content.image.height
							"
							:aspect-ratio="752 / 424"
							:show-background="false"
							:class="[
								'c-block-list-visual-shortcuts-custom__image',
								'absolute top-1/2 left-1/2',
								'transform -translate-x-1/2 -translate-y-1/2',
								'min-w-full min-h-full',
								'duration-500 ease-smooth-out',
								'transform group-hover:scale-105',
							]"
						/>
						<FallbackImage
							v-else
							class="absolute left-0 top-0 w-full h-full"
						/>

						<div
							:class="[
								'absolute top-0 left-0',
								'w-full h-full',
								'bg-text opacity-5',
							]"
						></div>

						<div
							:class="[
								'flex justify-items-stretch',
								'absolute bottom-0 right-0',
								'w-6/12col pb-lg pr-lg z-10',
							]"
						>
							<div
								:class="[
									'flex justify-between items-center',
									'bg-white px-xl py-lg w-full',
									'font-darker-grotesque font-bold text-title-card',
								]"
							>
								<div
									:class="[
										'c-block-list-visual-shortcuts-custom__image-button-text',
										'duration-500 ease-smooth-out  mb-4',
										'transform group-hover:translate-x-8',
									]"
									v-text="
										linkItems[index].content.linktext ||
										linkItems[index].content.link[0].name
									"
								></div>

								<div
									:class="[
										'flex-shrink-0 flex items-center justify-center',
										'w-32 h-32 bg-text rounded-full',
									]"
								>
									<SvgIconArrow class="w-16 text-white" />
								</div>
							</div>
						</div>
					</NuxtLinkExt>
				</div>
			</div>
		</div>

		<!-- Mobile -->
		<div class="relative >=1024:hidden">
			<!-- Mobile Lens Flares -->
			<LensFlare
				id="c-block-list-visual-shortcuts-custom__flare--mobile-first"
				:class="[
					'absolute top-6xl right-0',
					'transform -translate-y-1/2',
					'w-full >=605:w-1/2',
				]"
				:angle="-25"
				:min-blur-amount="35"
				:max-blur-amount="35"
				:flares="[
					{
						size: 600,
						offset: 0,
						opacity: 1,
						theme: 'primary',
					},
					{
						size: 600,
						offset: 350,
						opacity: 0.8,
						theme: 'secondary',
					},
				]"
			/>

			<LensFlare
				id="c-block-list-visual-shortcuts-custom__flare--mobile-second"
				:class="[
					'absolute bottom-6xl left-xl',
					'transform translate-y-1/2 -translate-x-1/2',
					'w-full >=605:w-1/2',
				]"
				:angle="25"
				:min-blur-amount="35"
				:max-blur-amount="35"
				:flares="[
					{
						size: 600,
						offset: 0,
						opacity: 1,
						theme: 'primary',
					},
					{
						size: 600,
						offset: 350,
						opacity: 0.8,
						theme: 'secondary',
					},
				]"
			/>

			<!-- Mobile Content -->
			<AccordionGroup :max-one-open="true" :min-one-open="true">
				<template v-for="(item, index) in linkItems">
					<AccordionHeader
						:id="`header-${_uid}-${index}`"
						:key="`header-${_uid}-${index}`"
						:aria-controls="`panel-${_uid}-${index}`"
						:class="[
							'relative flex justify-between items-center',
							'p-xl w-full group transition-colors duration-300 ease-smooth-out',
							{
								'bg-background text-black': panelsOpen[index],
								'bg-black text-white': !panelsOpen[index],
							},
						]"
					>
						<span
							class="
								font-darker-grotesque font-semibold
								text-h4
								mb-3
							"
							v-text="item.content.title"
						></span>

						<SvgIconCaret
							:class="[
								'w-14 mt-1 flex-shrink-0',
								'transform duration-300 ease-smooth-out',
								{ '-rotate-180': panelsOpen[index] },
							]"
						/>
					</AccordionHeader>

					<AccordionPanel
						:id="`panel-${_uid}-${index}`"
						:key="`panel-${_uid}-${index}`"
						:transition="{
							name: 't-block-list-visual-shortcuts-custom__panel',
						}"
						@change="(event) => onPanelChange(event, index)"
					>
						<NuxtLinkExt
							v-if="item.content.link[0]"
							:to="item.content.link[0]?.url || '#'"
							:target="item.content.link[0]?.target"
							:class="[
								'block',
								'w-full px-xl pb-xl',
								'duration-300 ease-smooth-out',

								{
									'bg-background': panelsOpen[index],
									'bg-text': !panelsOpen[index],
								},
							]"
						>
							<UmbracoImageExt
								v-if="item.content.image"
								:key="`image-${activeIndex}`"
								:widths="[345, 459, 752, 900, 1500]"
								:alt="item.content.image.altText"
								:source-url="item.content.image.cropUrl"
								:source-width="item.content.image.width"
								:source-height="item.content.image.height"
								:aspect-ratio="752 / 424"
								class="w-full"
							/>

							<div
								class="flex justify-between items-center mt-lg"
							>
								<div
									:class="[
										'font-darker-grotesque font-medium',
										'text-longread-mobile mb-2',
									]"
									v-text="
										item.content.linktext ||
										item.content.link[0].name
									"
								></div>

								<div
									:class="[
										'w-24 h-24 bg-text rounded-full',
										'flex justify-center items-center',
									]"
								>
									<SvgIconArrow
										:class="[
											'c-block-list-visual-shortcuts-custom__arrow-mobile',
											'text-white w-12',
										]"
									/>
								</div>
							</div>
						</NuxtLinkExt>
					</AccordionPanel>
				</template>
			</AccordionGroup>
		</div>
	</div>
</template>

<script>
import LensFlare from '~/components/shared/LensFlare';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import FallbackImage from '~/components/shared/FallbackImage';
import SvgIconArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgIconCaret from '~/assets/svgs/icon-caret.svg?inline';
import {
	AccordionGroup,
	AccordionHeader,
	AccordionPanel,
} from '~/citi-baseline/components/Accordion';

export default {
	name: 'BlockListVisualShortcutsCustom',

	components: {
		SvgIconArrow,
		SvgIconCaret,
		UmbracoImageExt,
		FallbackImage,
		LensFlare,
		AccordionGroup,
		AccordionHeader,
		AccordionPanel,
	},

	inheritAttrs: false,

	props: {
		title: String,
		linkItems: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			activeIndex: 0,
			activeItem: this.linkItems[0],
			panelsOpen: this.linkItems.map(() => false),
		};
	},

	methods: {
		setActiveItem(item) {
			this.activeItem = item;
			this.activeIndex = this.linkItems.indexOf(item);
		},

		onPanelChange(event, index) {
			this.$set(this.panelsOpen, index, event.isOpen);
		},

		tabsNavigateBy(amount) {
			const tabList = [
				...this.$el.querySelectorAll(
					'.c-block-list-visual-shortcuts-custom__tab'
				),
			];
			if (tabList.length > 1) {
				let currentFocus = this.$el.querySelector(
					'.c-block-list-visual-shortcuts-custom__tab:focus'
				);
				if (!currentFocus) {
					currentFocus = tabList[0];
				}
				const currentIndex = tabList.indexOf(currentFocus);
				let newIndex = currentIndex + amount;
				if (newIndex < 0) {
					newIndex = tabList.length - 1;
				} else if (newIndex >= tabList.length) {
					newIndex = 0;
				}

				tabList[newIndex].focus();
			}
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-visual-shortcuts-custom__desktop,
.c-block-list-visual-shortcuts-custom__buttons {
	min-height: 400px;
}

.c-block-list-visual-shortcuts-custom__image {
	aspect-ratio: 752 / 424 !important;
}

.c-block-list-visual-shortcuts-custom__caret path {
	stroke-width: 2px;
}

.t-block-list-visual-shortcuts-custom__panel-enter-active,
.t-block-list-visual-shortcuts-custom__panel-leave-active {
	@apply duration-300 ease-smooth-out;
}

.t-block-list-visual-shortcuts-custom__panel-enter,
.t-block-list-visual-shortcuts-custom__panel-leave-to {
	@apply max-h-0;
}
</style>
