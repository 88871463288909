var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-block-list-visual-shortcuts-custom"},[_c('h2',{class:[
			'text-label-spotbox font-darker-grotesque font-bold uppercase',
			'mb-lg',
		],domProps:{"textContent":_vm._s(_vm.title)}}),_vm._v(" "),_c('div',{class:[
			'c-block-list-visual-shortcuts-custom__desktop',
			'relative hidden >=1024:flex items-stretch',
		]},[_c('LensFlare',{class:[
				'absolute top-1/2 -left-6xl',
				'transform -translate-y-1/2',
				'w-1/2',
			],attrs:{"id":"c-block-list-visual-shortcuts-custom__flare--desktop","angle":-60,"min-blur-amount":35,"max-blur-amount":35,"flares":[
				{
					size: 700,
					offset: -75,
					opacity: 1,
					theme: 'primary',
				},
				{
					size: 700,
					offset: 200,
					opacity: 0.8,
					theme: 'secondary',
				},
			]}}),_vm._v(" "),_c('div',{class:[
				'c-block-list-visual-shortcuts-custom__buttons',
				'flex-1 flex flex-col',
			],attrs:{"role":"tablist"}},_vm._l((_vm.linkItems),function(item,index){return _c('button',{key:`button-${index}`,class:[
					'c-block-list-visual-shortcuts-custom__tab',
					'relative flex-1 flex justify-between items-center',
					'px-2xl py-xl text-left group',
					'font-semibold font-darker-grotesque text-h4',
					'duration-300 ease-smooth-out',

					{
						'text-white bg-text': _vm.activeIndex === index,
						'text-text bg-background': _vm.activeIndex !== index,
					},
				],attrs:{"id":`visual-list-tab-${_vm._uid}-${index}`,"aria-selected":_vm.activeIndex === index ? 'true' : 'false',"aria-controls":`visual-list-panel-${_vm._uid}-${index}`,"tabindex":_vm.activeIndex === index ? null : '-1',"role":"tab"},on:{"click":() => _vm.setActiveItem(item),"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return (() => _vm.tabsNavigateBy(-1)).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return (() => _vm.tabsNavigateBy(1)).apply(null, arguments)}]}},[_c('span',{class:[
						'mb-4 transform',
						'transition-transform duration-300 ease-smooth-out',

						{
							'group-hover:translate-x-12':
								_vm.activeIndex !== index,
						},
					],domProps:{"textContent":_vm._s(item.content.title)}}),_vm._v(" "),_c('SvgIconCaret',{class:[
						'c-block-list-visual-shortcuts-custom__caret',
						'h-24 text-white transform -rotate-90',
						'duration-300 ease-smooth-out',

						{
							'opacity-0': _vm.activeIndex !== index,
						},
					]}),_vm._v(" "),(
						index !== _vm.activeIndex &&
						index !== _vm.linkItems.length - 1
					)?_c('span',{class:[
						'absolute left-0 -bottom-1 z-10',
						'flex w-full px-2xl',
					]},[_c('span',{staticClass:"flex-1 border-b border-text opacity-20"})]):_vm._e()],1)}),0),_vm._v(" "),_c('div',{class:[
				'relative group',
				'w-7/12col flex-shrink-0 overflow-hidden',
				'bg-secondary u-color-theme-transition',
			]},_vm._l((_vm.linkItems),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeIndex),expression:"index === activeIndex"}],key:`panel-${index}`,staticClass:"absolute left-0 top-0 w-full h-full transform",attrs:{"id":`visual-list-panel-${_vm._uid}-${index}`,"role":"tabpanel","aria-expanded":index === _vm.activeIndex ? 'true' : 'false',"aria-hidden":index === _vm.activeIndex ? 'false' : 'true'}},[_c('NuxtLinkExt',{staticClass:"absolute left-0 top-0 w-full h-full transform",attrs:{"to":item.content.link[0]?.url || '#',"target":item.content.link[0]?.target}},[(
							_vm.linkItems[index] &&
							_vm.linkItems[index].content.image
						)?_c('UmbracoImageExt',{class:[
							'c-block-list-visual-shortcuts-custom__image',
							'absolute top-1/2 left-1/2',
							'transform -translate-x-1/2 -translate-y-1/2',
							'min-w-full min-h-full',
							'duration-500 ease-smooth-out',
							'transform group-hover:scale-105',
						],attrs:{"widths":[345, 459, 752, 459 * 2, 752 * 2],"alt":_vm.linkItems[index].content.image.altText,"source-url":_vm.linkItems[index].content.image.cropUrl,"source-width":_vm.linkItems[index].content.image.width,"source-height":_vm.linkItems[index].content.image.height,"aspect-ratio":752 / 424,"show-background":false}}):_c('FallbackImage',{staticClass:"absolute left-0 top-0 w-full h-full"}),_vm._v(" "),_c('div',{class:[
							'absolute top-0 left-0',
							'w-full h-full',
							'bg-text opacity-5',
						]}),_vm._v(" "),_c('div',{class:[
							'flex justify-items-stretch',
							'absolute bottom-0 right-0',
							'w-6/12col pb-lg pr-lg z-10',
						]},[_c('div',{class:[
								'flex justify-between items-center',
								'bg-white px-xl py-lg w-full',
								'font-darker-grotesque font-bold text-title-card',
							]},[_c('div',{class:[
									'c-block-list-visual-shortcuts-custom__image-button-text',
									'duration-500 ease-smooth-out  mb-4',
									'transform group-hover:translate-x-8',
								],domProps:{"textContent":_vm._s(
									_vm.linkItems[index].content.linktext ||
									_vm.linkItems[index].content.link[0].name
								)}}),_vm._v(" "),_c('div',{class:[
									'flex-shrink-0 flex items-center justify-center',
									'w-32 h-32 bg-text rounded-full',
								]},[_c('SvgIconArrow',{staticClass:"w-16 text-white"})],1)])])],1)],1)}),0)],1),_vm._v(" "),_c('div',{staticClass:"relative >=1024:hidden"},[_c('LensFlare',{class:[
				'absolute top-6xl right-0',
				'transform -translate-y-1/2',
				'w-full >=605:w-1/2',
			],attrs:{"id":"c-block-list-visual-shortcuts-custom__flare--mobile-first","angle":-25,"min-blur-amount":35,"max-blur-amount":35,"flares":[
				{
					size: 600,
					offset: 0,
					opacity: 1,
					theme: 'primary',
				},
				{
					size: 600,
					offset: 350,
					opacity: 0.8,
					theme: 'secondary',
				},
			]}}),_vm._v(" "),_c('LensFlare',{class:[
				'absolute bottom-6xl left-xl',
				'transform translate-y-1/2 -translate-x-1/2',
				'w-full >=605:w-1/2',
			],attrs:{"id":"c-block-list-visual-shortcuts-custom__flare--mobile-second","angle":25,"min-blur-amount":35,"max-blur-amount":35,"flares":[
				{
					size: 600,
					offset: 0,
					opacity: 1,
					theme: 'primary',
				},
				{
					size: 600,
					offset: 350,
					opacity: 0.8,
					theme: 'secondary',
				},
			]}}),_vm._v(" "),_c('AccordionGroup',{attrs:{"max-one-open":true,"min-one-open":true}},[_vm._l((_vm.linkItems),function(item,index){return [_c('AccordionHeader',{key:`header-${_vm._uid}-${index}`,class:[
						'relative flex justify-between items-center',
						'p-xl w-full group transition-colors duration-300 ease-smooth-out',
						{
							'bg-background text-black': _vm.panelsOpen[index],
							'bg-black text-white': !_vm.panelsOpen[index],
						},
					],attrs:{"id":`header-${_vm._uid}-${index}`,"aria-controls":`panel-${_vm._uid}-${index}`}},[_c('span',{staticClass:"font-darker-grotesque font-semibold text-h4 mb-3",domProps:{"textContent":_vm._s(item.content.title)}}),_vm._v(" "),_c('SvgIconCaret',{class:[
							'w-14 mt-1 flex-shrink-0',
							'transform duration-300 ease-smooth-out',
							{ '-rotate-180': _vm.panelsOpen[index] },
						]})],1),_vm._v(" "),_c('AccordionPanel',{key:`panel-${_vm._uid}-${index}`,attrs:{"id":`panel-${_vm._uid}-${index}`,"transition":{
						name: 't-block-list-visual-shortcuts-custom__panel',
					}},on:{"change":(event) => _vm.onPanelChange(event, index)}},[(item.content.link[0])?_c('NuxtLinkExt',{class:[
							'block',
							'w-full px-xl pb-xl',
							'duration-300 ease-smooth-out',

							{
								'bg-background': _vm.panelsOpen[index],
								'bg-text': !_vm.panelsOpen[index],
							},
						],attrs:{"to":item.content.link[0]?.url || '#',"target":item.content.link[0]?.target}},[(item.content.image)?_c('UmbracoImageExt',{key:`image-${_vm.activeIndex}`,staticClass:"w-full",attrs:{"widths":[345, 459, 752, 900, 1500],"alt":item.content.image.altText,"source-url":item.content.image.cropUrl,"source-width":item.content.image.width,"source-height":item.content.image.height,"aspect-ratio":752 / 424}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex justify-between items-center mt-lg"},[_c('div',{class:[
									'font-darker-grotesque font-medium',
									'text-longread-mobile mb-2',
								],domProps:{"textContent":_vm._s(
									item.content.linktext ||
									item.content.link[0].name
								)}}),_vm._v(" "),_c('div',{class:[
									'w-24 h-24 bg-text rounded-full',
									'flex justify-center items-center',
								]},[_c('SvgIconArrow',{class:[
										'c-block-list-visual-shortcuts-custom__arrow-mobile',
										'text-white w-12',
									]})],1)])],1):_vm._e()],1)]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }